import React, { useContext } from 'react';
import { PageContext } from '@/context/PageContext';
import Helmet from 'react-helmet';

import Section from '@latitude/section';
import Layout from '@/components/layout';
import AchPageHeaderSection from './_ach-page-header';
import LowRateMicrositeHeader from './../_low-rate-header';
import HeroBanner from '@/components/lab-components/HeroBanner';

function AchNewPasswordPage(props) {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */
  return (
    <Layout location={props.location}>
      <main className="navigation-spacer">
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/credit-cards/low-rate/addcard/new-password/"
          />
          <meta
            name="description"
            content="Add an additional cardholder to your Latitude Low Rate Mastercard"
          />
          <title>Additional Card Holder New Password | Low Rate Mastercard</title>
        </Helmet>

        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <AchPageHeaderSection />
        )}
        <LowRateMicrositeHeader />
        <Section>
          <div
            css={{ clear: 'both', margin: '10px 0' }}
            className="lfs-iframe-vixverify"
          >
            <iframe
              css={{
                width: '100%',
                height: '400px',
                border: 'none',
                padding: '10px'
              }}
              title="Set new password"
              src="https://au.vixverify.com/sa/customers/gecapital-ach/new-password.seam?ui=lowrate"
            />
          </div>
        </Section>
      </main>
    </Layout>
  );
}

export default AchNewPasswordPage;
